.email-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.email-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 75%;
  height: 75%;
  overflow-y: auto;
  position: relative;
}

@media (max-width: 768px) {
  .email-content {
    width: 80%;
  }
}

.email-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.email-close-button {
  position: relative;
  cursor: pointer;
  font-size: 50px;
  background: transparent;
  color: #333;
}

.email-close-button:hover {
  color: #FE6148;
  transition: 0.1s ease;
}

.name-fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.email-message {
  height: 200px;
}

button[type="submit"] {
  padding: 10px 20px;
  border: none;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

button[type="submit"]:hover {
  background-color: #555;
}