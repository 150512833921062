.showreels-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    /* Short term solution, replace with dynamic repositioning */
}

@media (max-width: 768px) {
    .showreels-container {
        grid-template-columns: 1fr;
    }
  }

.video-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: 0.1s ease-in-out;
}

.video-wrapper:hover {
    transform: translateY(-8px);
    transition: 0.1s ease-in-out;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 25px;
  }