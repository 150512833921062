.footer {
  background-color: #fff;
  color: #333;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}