* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, #root, .App {
  height: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  width: 100%;
}

body {
  overflow-x: hidden;
}

