.header {
  background-color: #fff;
  color: #333;
  padding: 15px 30px;
  width: 100%;
  position: fixed;
  height: 120px;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.site-profile-logo {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 15px;
}

.profile {
  width: 100px;
  border-radius: 100%;
  object-fit: contain;
  flex-shrink: 0;
}

.logo {
  width: 400px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .header {
    justify-content: center;
  }
  
  .site-profile-logo {
    flex-direction: column;
    object-fit: contain;
    gap: 5px;
  }

  .profile {
    width: 75px;
  }

  .logo {
    width: 200px;
  }
}

.header-dropdown {
  display: flex;
  flex-direction: row;
}

.header-dropdown > h2 {
  padding: 1rem;
}