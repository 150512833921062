/* TODO: Animate dropdown */

.dropdown {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.hamburger-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  transition: 0.1s ease;
}

.hamburger-menu:hover {
  color: #FE6148;
}

@media (max-width: 768px) {
  .hamburger-menu {
    font-size: 50px;
    color: #333;
  }

}

/* TODO: Animate hamburger icon to turn into 'X' when clicked */

.dropdown-menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  list-style: none;
  margin: 20px;
  z-index: 1000;
}

h3 {
  font-size: 1.25rem;
}

.dropdown-menu li {
  padding: 10px 15px;
}

.connect,
.listen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.connect-header,
.listen-header {
  margin-top: 15px;
  width: 75%;
  text-align: center;
}

.social-icons {
  display: flex;
  flex-direction: row;
}

li {
  cursor: pointer;
}

.social-icons>li>a {
  color: #333;
  text-decoration: none;
  font-size: 30px;
}

.social-icons>li:hover>a {
  color: #FE6148;
}

.email-button {
  background: none;
  border: none;
}