/* TODO: Add slide in from left animation*/

.project-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .project-overlay {
    flex-direction: column;
  }

  .project-content {
    min-width: 100vw;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    background-color: #333;
    color: #fff;
    display: flex;
    /* flex-direction: column; */
  }
}

h2,
h3,
h4 {
  text-transform: uppercase;
  padding: 5px 0;
}

p {
  padding: 20px 0;
  text-align: justify;
}

h2 {
  font-size: 2rem;
}

@media (max-width: 375px) {
 .project-details h3 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
  }
}

a,
button {
  color: #fff;
  cursor: pointer;
  border: none;
  transition: 0.1s ease;
}

a:hover,
button:hover {
  color: #FE6148;
}

.title-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.play-icon {
  font-size: 50px;
  color: #fff;
  transition: 0.1s ease;
  display: inline-block;
}

.play-icon:hover {
  color: #FE6148;
  transition: 0.1s ease;
}

.project-content {
  width: 50%;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
  background-color: #333;
  color: #fff;
  animation: normal;
  display: flex;
  flex-direction: column;
}

.project-banner {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.content-wrapper {
  padding: 20px 50px 70px 50px;
}

.videos-container {
  width: 100%;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio */
}

/* TODO: Increase video player size on mobile view. */

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
}

.project-details {
  display: flex;
  flex-direction: column;
}

.release-date, .developer, .role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.denominator-title {
  color: #999;
  font-size: 1rem;
  text-align: left;
}

.denominator-text {
  font-size: 1rem;
  text-align: right;
}

.role-title {
  font-size: 1rem;
  color: #999;
}

.role-text {
  font-size: 1rem;
  text-align: right;
  color:#FE6148;
}

/* For Firefox */
.project-overlay::-webkit-scrollbar {
  width: 8px;
  display: none;
}

.project-overlay::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.mproject-overlay::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border: 2px solid transparent;
}

.project-overlay::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/* For Firefox */
.project-overlay {
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);
}

/* TODO: Extend project close button to entire width of overlay */

.project-close-button {
  position: relative;
  height: 100%;
  font-size: 50px;
  text-align: left;
  justify-content: center;
  transition: 0.1s ease;
  background-color: transparent;
}

.project-close-button:hover {
  transform: rotateY(180deg);
  transition: 0.1s ease;
}

.mobile-project-close-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mobile-project-close-button {
  position: relative;
  color: #333;
  background-color: transparent;
  height: 50px;
  font-size: 50px;
  border: none;
  transition: 0.1s ease;
  justify-content: center;
  align-items: center;
  text-align: center;
}
