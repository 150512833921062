.discord-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.discord-modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media (max-width: 768px) {
    .discord-modal-content {
        max-width: 80%;
    }
}

.discord-modal-content > h3 {
    width: 100%;
    border-radius: 8px;
    background-color: #FE6148;
    color: #fff;
    margin-bottom: 15px;
}

.discord-link {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.discord-username {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.copy-text-button {
    background: none; /* Remove background color */
    color: #333;      /* Set text color */
    font-size: 20px;  /* Keep the font size */
    border: none;     /* Remove any border */
    padding: 0;       /* Remove padding if needed */
    cursor: pointer;  /* Show pointer on hover */
    outline: none;    /* Remove any focus outline */
}

.discord-icon {
    color: #333;
    font-size: 50px;
    background: none;
}

.discord-modal-close-container {
    width: 100%;
    text-align: center;
}

.discord-modal-close-button {
    background: transparent;
    color: #333;
    border: none;
    cursor: pointer;
    font-size: 50px;
    margin-top: 15px
}