.hamburger-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.hamburger-content {
    background: #fff;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
}

.connect-header, .listen-header {
    width: 60%;
    background-color: #FE6148;
    color: #fff;
    border-radius: 8px;
    margin: 15px;
}

.hamburger-close-button {
    background: transparent;
    color: #333;
    border: none;
    cursor: pointer;
    font-size: 50px;
    margin-top: 15px
}

.social-icons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    list-style: none;
    padding: 5px;
}

.border-short {
    border-bottom: 2px solid #FE6148;
    width: 75%;
}
